import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Tabs from "../components/Tabs/Tabs";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Sustainability | Hotel Cleaning Services"
        description="Sustainability is not just about the end product. Practices, production, transportation, and distribution are all important when it comes to setting standards."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative mb-28 md:mb-32 overflow-x-hidden md:overflow-visible">
        <div className="relative md:static mr-14 md:mr-0">
          <div className="md:absolute md:right-0">
            <GatsbyImage
              image={data.hero.childImageSharp.gatsbyImageData}
              className="md:h-full  md:w-[60vw] ml-4 -mr-16"
            />
          </div>
          <div className="border border-primary-600 max-w-[585px] max-h-[576px] w-full h-[92.5%] absolute top-14 left-0 md:hidden"></div>
        </div>
        <div className="container relative">
          <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-18 items-center md:h-[704px]">
            <header className="md:col-start-1 md:col-span-11 md:pt-36 z-10">
              <h1 className="relative md:left-10 lg:left-24 max-w-xl -top-12 md:top-0 -mt-2 md:mt-0 mb-0 md:mb-6">
                Sustainability
              </h1>
              <div className="md:max-w-sm">
                <p className="mb-0 text-sm md:text-base">
                  Sustainability is not just about the end product. Practices,
                  production, transportation, and distribution are all equally
                  important when it comes to setting the standards. We’ve
                  partnered with national companies to provide the most
                  up-to-date green practices on the market today. And we bring
                  that expertise to our clients.
                </p>
              </div>
            </header>

            <div className="border border-primary-600 w-[708px] h-[588px] absolute top-36 right-24 md:col-end-13 md:col-span-1 hidden md:block"></div>
          </div>
        </div>
      </section>

      <section className="mb-24 md:mb-40">
        <div className="container">
          <div className="text-center">
            <blockquote className="max-w-[800px] mx-auto text-center">
              <q className="md:text-xl tracking-wider md:tracking-normal text-gray-200">
                With the advances in cleaning chemicals, equipment, and machines
                in recent years, there is NO REASON service providers in the
                cleaning industry should not exceed the standards set for green
                cleaning.
              </q>
              <footer className="mt-12 md:mt-10 mx-auto">
                <div>
                  <cite className="text-sm text-gray-50 uppercase tracking-wide not-italic">
                    <span className="font-bold">
                      Tim Bottoms, VP of Operations
                    </span>
                    <span className="font-bold hidden md:inline mx-1">/</span>
                    <span className="block md:inline">
                      Hotel Cleaning Services
                    </span>
                  </cite>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      <section className="mb-24 md:mb-40">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-y-8 md:gap-x-10 lg:gap-x-18">
            <div className="relative md:col-start-1 md:col-span-6 -mx-4 md:mx-0">
              <GatsbyImage
                image={data.greenPractices.childImageSharp.gatsbyImageData}
                width="560"
                className="w-full"
              />
            </div>

            <div className="md:col-end-13 md:col-span-6 md:mt-20">
              <h2 className="relative md:-left-32 md:mb-10">
                Our Green Practices
              </h2>
              <p className="text-sm md:text-base">
                We’ll enhance your hotel, resort or commercial building with
                programmed maintenance in compliance with leading standards. We
                use certified products and equipment and our customized
                ‘critical path’ service plans include:
              </p>
              <ul className="list-disc pl-6 text-sm md:text-base tracking-wider">
                <li>Green Standard Operating Procedures</li>
                <li>Certified Environmentally Approved Products & Supplies</li>
                <li>Certified Eco-Friendly Equipment</li>
                <li>Recycling</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-22 md:mb-32">
        <div className="container">
          <header className="mb-12">
            <h2>Our Green Certifications</h2>
          </header>
          <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1 md:mt-14 lg:mt-28">
              <Tabs>
                <div
                  label="CIMS Certified with Honors"
                  image={data.cimsHonors.childImageSharp.gatsbyImageData}
                >
                  <h3 className="text-xl tracking-wide">
                    Cleaning Industry Management Standard (CIMS)
                  </h3>
                  <p>
                    CIMS is the first consensus-based management standard that
                    outlines the primary characteristics of a successful,
                    quality cleaning organization.
                  </p>

                  <p>
                    This certification differentiates our organization from the
                    competition. It demonstrates our commitment to quality and
                    customer satisfaction, improving our overall operations, and
                    saving money.
                  </p>
                </div>
                <div
                  label="CIMS GB Certified with Honors"
                  image={data.cimsGBHonors.childImageSharp.gatsbyImageData}
                >
                  <h3 className="text-xl tracking-wide">
                    CIMS - Green Building (GB)
                  </h3>
                  <p>
                    The CIMS Green Building criteria is an optional
                    certification designed to secure points under the LEED for
                    Existing Buildings: Operations and Maintenance (LEED EB:
                    O+M) Green Building Rating System.
                  </p>
                  <p>
                    This additional certification demonstrates our capability of
                    partnering with our customers in the LEED process by helping
                    them achieve LEED EB: O+M points.
                  </p>
                </div>
                <div
                  label="USGBC"
                  image={data.USGBC.childImageSharp.gatsbyImageData}
                >
                  <h3 className="text-xl tracking-wide">LEED Certification</h3>
                  <p>
                    LEED: Building Operations and Maintenance (O+M) targets
                    existing buildings, retail, schools, hospitality, data
                    centers, warehouses, and distribution centers.
                  </p>
                  <p>
                    Cleaning and Maintenance activities make up 25% of the total
                    points required to obtain LEED (O+M) certification. We will
                    provide the cleaning chemicals, equipment, supplies, and
                    support necessary to make sure you comply with the standard.
                  </p>
                  <p>
                    Whether your building is already LEED-certified or you are
                    considering starting the process to obtain your LEED
                    certification, Hotel Cleaning Services is your trusted
                    partner to help you keep or achieve that coveted
                    distinction.
                  </p>
                </div>
              </Tabs>
            </div>

            <div className="flex justify-end order-1 md:order-2 -mx-4 md:mx-0">
              <GatsbyImage
                image={data.greenCertifications.childImageSharp.gatsbyImageData}
                width="560"
                className="w-full md:ml-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="text-center md:mb-14">
            <p className="font-bold uppercase text-gray-50 md:text-gray-50/50 tracking-widest mb-0">
              Our Green Partners
            </p>
          </header>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 opacity-40 max-w-[1000px] mx-auto">
            <GatsbyImage
              image={data.CIMSHonorsLogo.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.EcoLogo.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.greenSealLogo.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage
              image={data.greenguardLogo.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.criLogo.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={
                data.environmentalChoiceLogo.childImageSharp.gatsbyImageData
              }
            />
            <GatsbyImage
              image={data.leedLogo.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.epaLogo.childImageSharp.gatsbyImageData} />
            <GatsbyImage
              image={data.indoorAirQualityLogo.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage image={data.soaLogo.childImageSharp.gatsbyImageData} />
          </div>
        </div>
      </section>

      <CallToAction
        heading={[
          "Let’s Become ",
          <span className="text-primary-500">Partners</span>,
        ]}
        subtext="From short-term help to long-term staff members for your hotel, restaurant, or other establishments, we can get you the staffing you need. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Sustainability.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Sustainability.jpg" }
    ) {
      publicURL
    }
    hero: file(
      relativePath: { eq: "sustainability/1.0 Hero Sustainability.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 817)
      }
    }
    greenPractices: file(
      relativePath: { eq: "sustainability/2.0 Sustainability Green.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    greenCertifications: file(
      relativePath: { eq: "sustainability/Green Certifications.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    cimsHonors: file(relativePath: { eq: "about-us/CIMS-Honors-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 96)
      }
    }
    cimsGBHonors: file(
      relativePath: { eq: "about-us/CIMS-GB-Honors-logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 98)
      }
    }
    USGBC: file(relativePath: { eq: "about-us/USGBC-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 65)
      }
    }
    soaLogo: file(relativePath: { eq: "sustainability/10 SOA logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    indoorAirQualityLogo: file(
      relativePath: { eq: "sustainability/9.0 IndoorGold logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    epaLogo: file(relativePath: { eq: "sustainability/8.0 EPA logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    leedLogo: file(relativePath: { eq: "sustainability/7.0 LEED logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    environmentalChoiceLogo: file(
      relativePath: { eq: "sustainability/6.0 Environmental-choice-Logo 1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    criLogo: file(relativePath: { eq: "sustainability/5.0 CRI logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    greenguardLogo: file(
      relativePath: { eq: "sustainability/4.0 greenguard logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    greenSealLogo: file(
      relativePath: { eq: "sustainability/3.0 green seal logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    EcoLogo: file(relativePath: { eq: "sustainability/2.0 Ecologo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
    CIMSHonorsLogo: file(
      relativePath: { eq: "sustainability/1.0 CIMS-Honors.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 193)
      }
    }
  }
`;
export default Page;
